import React from 'react'
import TextCard from '~/components/molecules/TextCard'
import Image from '~/components/atoms/Image'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function TwoColumnFeatures({ key, index, ...props }) {

  const section = props.twoColumnFeatures


  return (
    <section id={section.fieldGroupName + index} className="my-20 md:my-36 ">
      <div className="container relative">
        <div className="grid grid-cols-12 gap-y-8 md:gap-y-0 items-start relative grid-flow-row-dense">
          <div className="col-span-12 lg:col-span-5">
            <TextCard {...section.textCard} />
          </div>
          <div className={`col-span-12 lg:col-span-5 lg:col-start-7`}>
            {section.features && Array.isArray(section.features) ? (
              section.features.map((item, i) => (
                <FadeInWhenVisible key={`feature${i}`} className="mb-12 last:mb-0 flex items-start">
                  <Image data={item.illustration} className="w-48 block h-auto" img objectFit="contain" />
                  <div className="ml-8 inline-block">
                    <h5 className="text-h5 mb-2.5">{item.heading}</h5>
                    <p>{item.content}</p>
                  </div>
                </FadeInWhenVisible>
              ))
            ) : (
              <p>No features available.</p> // Fallback UI
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
